import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCrud from "../../hooks/useCRUD";
import MessageDialog from "../MessageDialog";
import location from "../../constants/location.json";

function InvestmentDemandForm() {
	const { i18n, t } = useTranslation();
	const [successOpen, setSuccessOpen] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [showOtherSector, setShowOtherSector] = useState(false);
	const [showOtherInfo, setShowOtherInfo] = useState(false);

	const { createResource, isLoading } = useCrud("investmentDemand");
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
    try {
      await createResource(data, { lang: i18n.language })
      setSuccessOpen(true)
      reset()
    } catch (error) {
      setErrorOpen(true)
    }
  }

  return (
    <>
      <div className='relative w-screen flex items-center justify-center bg-white' id='investment-demand-form'>
        <div className='absolute top-0 left-0'>
          <img src='/assets/images/bg-top-left.webp' alt='' className='w-[25vmax] h-[25vmax]' />
        </div>
        <div className='absolute bottom-0 right-0'>
          <img src='/assets/images/bg-bottom-right.webp' alt='' className='w-[25vmax] h-[25vmax]' />
        </div>

        <div className='z-40 py-16 px-8 w-full flex justify-center'>
          <form onSubmit={handleSubmit(onSubmit)} className='max-w-5xl w-full rounded-lg mr-2'>
            <div className='text-center mb-8'>
              <h2 className='font-semibold text-gray-800'>{t('titleInvestDemand')}</h2>
              <h5 className='text-gray-600 mt-4'>{t('descInvestDemand')}</h5>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
              {/* Left Column */}
              <div className='w-full'>
                <div className='w-full'>
                  <label htmlFor='visitor_name' className='block text-gray-700'>
                    {t('visitorName')}
                  </label>
                  <input
                    type='text'
                    id='visitor_name'
                    placeholder={t('inputVisitorName')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('visitor_name', {
                      required: t('requiredName')
                    })}
                  />
                  {errors.visitor_name && <span className='text-red-500 text-sm'>{errors.visitor_name.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='organization' className='block text-gray-700'>
                    {t('company')}
                  </label>
                  <input
                    type='text'
                    id='organization'
                    placeholder={t('inputCompanyName')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('organization', {
                      required: t('requiredOrganization')
                    })}
                  />
                  {errors.organization && <span className='text-red-500 text-sm'>{errors.organization.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='email' className='block text-gray-700'>
                    {t('email')}
                  </label>
                  <input
                    type='email'
                    id='email'
                    placeholder={t('inputEmail')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('email', {
                      required: t('requiredEmail'),
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: t('invalidEmailFormat')
                      }
                    })}
                  />
                  {errors.email && <span className='text-red-500 text-sm'>{errors.email.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='phone_number' className='block text-gray-700'>
                    {t('phone')}
                  </label>
                  <input
                    type='tel'
                    id='phone_number'
                    placeholder={t('inputPhone')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('phone_number', {
                      required: t('requiredPhone')
                    })}
                  />
                  {errors.phone_number && <span className='text-red-500 text-sm'>{errors.phone_number.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='sector' className='block text-gray-700'>
                    {t('sector')}
                  </label>
                  <select
                    id='sector'
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('sector', { required: t('requiredSector') })}
                    defaultValue=''
                  >
                    <option value='' disabled>
                      {t('chooseSector')}
                    </option>
                    <option value='Agriculture'>Agriculture</option>
                    <option value='Fishery'>Fishery</option>
                    <option value='Energy'>Energy</option>
                    <option value='Industry'>Industry</option>
                    <option value='Manufacture'>Manufacture</option>
                    <option value='Tourism'>Tourism</option>
                    <option value='Infrastructure'>Infrastructure</option>
                    <option value='Others'>Others</option>
                  </select>
                </div>
                {errors.sector && <span className='text-red-500 text-sm'>{errors.sector.message}</span>}
              </div>

              {/* Right Column */}
              <div className='w-full'>
                <div className='w-full'>
                  <label htmlFor='estimation_area' className='block text-gray-700'>
                    {t('estimationArea')}
                  </label>
                  <input
                    type='number'
                    min={0}
                    id='estimation_area'
                    placeholder={t('inputEstimationArea')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('estimation_area', {
                      required: t('requiredEstimationArea')
                    })}
                  />
                  {errors.estimation_area && <span className='text-red-500 text-sm'>{errors.estimation_area.message}</span>}
                </div>

                <div className='w-full'>
                  <label htmlFor='prefered_location' className='block text-gray-700'>
                    {t('preferedLocation')}
                  </label>
                  <select
                    id='prefered_location'
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('prefered_location', {
                      required: t('requiredLocation')
                    })}
                    defaultValue=''
                  >
                    <option value='' disabled>
                      {t('chooseLocation')}
                    </option>
                    {location.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.post_title_id}
                        </option>
                      )
                    })}
                  </select>
                  {errors.prefered_location && <span className='text-red-500 text-sm'>{errors.prefered_location.message}</span>}
                </div>

                <div className='w-full'>
                  <label htmlFor='wished_sector' className='block text-gray-700'>
                    {t('wishedSector')}
                  </label>
                  <select
                    id='wished_sector'
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('wished_sector', {
                      required: t('requiredWishedSector')
                    })}
                    onChange={(e) => setShowOtherSector(e.target.value === 'Others')}
                    defaultValue=''
                  >
                    <option value='' disabled>
                      {t('chooseWishedSector')}
                    </option>
                    <option value='Agriculture'>Agriculture</option>
                    <option value='Fishery'>Fishery</option>
                    <option value='Energy'>Energy</option>
                    <option value='Industry'>Industry</option>
                    <option value='Manufacture'>Manufacture</option>
                    <option value='Tourism'>Tourism</option>
                    <option value='Infrastructure'>Infrastructure</option>
                    <option value='Others'>Others</option>
                  </select>
                  {showOtherSector && (
                    <input
                      type='text'
                      id='other_wished_sector'
                      placeholder={t('otherSector')}
                      className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                      {...register('other_wished_sector')}
                    />
                  )}

                  {errors.wished_sector && <span className='text-red-500 text-sm'>{errors.wished_sector.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='required_info' className='block text-gray-700'>
                    {t('requiredInfo')}
                  </label>
                  <select
                    id='required_info'
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('required_info', {
                      required: t('requiredRequiredInfo')
                    })}
                    onChange={(e) => setShowOtherInfo(e.target.value === 'Others')}
                  >
                    <option value='' disabled>
                      {t('chooseRequiredInfo')}
                    </option>
                    <option value='Licensing Process'>Licensing Process</option>
                    <option value='Labor'>Labor</option>
                    <option value='Raw Materials'>Raw Materials</option>
                    <option value='Industrial Area'>Industrial Area</option>
                    <option value='Infrastructure'>Infrastructure</option>
                    <option value='Ready to Offer Projects'>Ready to Offer Projects</option>
                    <option value='Economic Outlook'>Economic Outlook</option>
                    <option value='Others'>Others</option>
                  </select>
                  {showOtherInfo && (
                    <input
                      type='text'
                      id='other_info'
                      placeholder={t('otherInfo')}
                      className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                      {...register('other_info')}
                    />
                  )}
                  {errors.required_ifo && <span className='text-red-500 text-sm'>{errors.required_ifo.message}</span>}
                </div>
                <div className='w-full'>
                  <label htmlFor='additional_notes' className='block text-gray-700'>
                    {t('additional')}
                  </label>
                  <textarea
                    id='additional_notes'
                    placeholder={t('writeNotes')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('additional_notes')}
                  />
                </div>
              </div>
            </div>
            {!isLoading && (
              <button type='submit' className='w-full mt-8 py-2 bg-primary text-white font-semibold rounded-lg hover:bg-primary transition-colors'>
                {t('submit')}
              </button>
            )}
          </form>
        </div>
      </div>

      {/* Success dialog */}
      <MessageDialog open={successOpen} onOpenChange={setSuccessOpen} title={t('message-sent')} description={t('thank-you-contacting')} />

      {/* Error dialog */}
      <MessageDialog open={errorOpen} onOpenChange={setErrorOpen} title={t('error')} description={t('failed-to-send-message')} isError={true} />
    </>
  )
}

export default InvestmentDemandForm;
