import React from "react";
import * as Dialog from '@radix-ui/react-dialog'

const ModalDialog = ({ open, onOpenChange, title, description, children }) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content className='DialogContent'>
          <Dialog.Title className='text-3xl font-semibold'>{title}</Dialog.Title>

          <Dialog.Description className='text-slate-600'>{description}</Dialog.Description>

          <div>{children}</div>

          <Dialog.Close asChild></Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ModalDialog;
