import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { industrialAreaStore, infrastructureStore } from '../../../stores/_rootStore'
import Card from '../../Card'

function FacilityInfrastructureSection({ category }) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (category === 'industrial-area-invest' && industrialAreaStore.data.length === 0) {
          await industrialAreaStore.fetchData()
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
    return () => {
      if (category === 'industrial-area-invest') industrialAreaStore.resetState()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-5'>{t(category)}</h3>
      {category === 'sdm-long' && (
        <div className='flex flex-col gap-2 mb-5'>
          <img
            src='/assets/images/investment/sdm-1.png'
            alt='SDM Jateng 1'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/sdm-2.png'
            alt='SDM Jateng 2'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/sdm-3.png'
            alt='SDM Jateng 3'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
        </div>
      )}
      {category === 'infrastructure' && (
        <div className='flex flex-col gap-2 mb-5'>
          <img
            src='/assets/images/investment/infra-1.png'
            alt='Infrastructur Jateng 1'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-2.png'
            alt='Infrastructur Jateng 2'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-3.png'
            alt='Infrastructur Jateng 3'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-4.png'
            alt='Infrastructur Jateng 4'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
        </div>
      )}
      {category === 'industrial-area-invest' && (
        <div className='flex md:justify-end'>
          <div className='relative'>
            <input
              type='text'
              className='border border-gray-300 py-2 pl-2 pr-7 w-full focus:outline-none focus:border-gold'
              placeholder={t('search')}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (category === 'industrial-area-invest') {
                    industrialAreaStore.search(searchTerm)
                  }
                  if (category === 'infrastructure') {
                    infrastructureStore.search(searchTerm)
                  }
                }
              }}
            />
            <i className='fa fa-search absolute right-2 top-3 text-gray-300'></i>
          </div>
        </div>
      )}
      <div className='grid grid-cols-2 md:grid-cols-3 gap-4 py-5'>
        {category === 'industrial-area-invest' &&
          (industrialAreaStore.isLoading ? (
            <p>Loading...</p>
          ) : industrialAreaStore.data.length > 0 ? (
            industrialAreaStore.data.map((post) => (
              <Card
                key={post.id}
                imgSrc={`${process.env.REACT_APP_IMAGE_URL}${post.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
                label={post.post_title}
                href={`/investment/industrial-area/${post.post_name}`}
              />
            ))
          ) : (
            <p>{t('no-data')}</p>
          ))}
      </div>
      <div className='flex justify-center'>
        {category === 'industrial-area-invest' && industrialAreaStore.hasNext && (
          <button className='bg-gold text-white py-2 px-4 rounded-md' onClick={industrialAreaStore.nextPage}>
            {t('view-more')}
          </button>
        )}
      </div>
    </div>
  )
}

export default observer(FacilityInfrastructureSection)
