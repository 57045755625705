import React, { useEffect, useState } from 'react'
import { newsDetailStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeadingUnderline from '../../components/HeadingUnderline'
import Breadcrumb from '../../components/Breadcrumb'
import Card from '../../components/Card'
import i18n from '../../i18n'

function NewsDetail() {
  const { postName } = useParams()
  const { t } = useTranslation()
  const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('newsevent') }, { label: newsDetailStore.data?.post_title }]
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await newsDetailStore.fetchData(postName)
        await newsDetailStore.fetchOtherData()
      } catch (error) {
        console.error(error)
      }
    }
    setShowBanner(true)
    fetchData()
    // eslint-disable-next-line
  }, [postName, i18n.language, postName])

  return (
    <div className='flex flex-col justify-center mb-12'>
      <div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
        <Breadcrumb items={breadcrumbItems} />
        <div className='grid grid-cols-1 md:grid-cols-10 gap-10 gap-y-5 mt-5'>
          <div className='md:col-span-7'>
            <h1 className='text-3xl font-bold mb-2 mt-8'>{newsDetailStore.data?.post_title}</h1>
            <HeadingUnderline />
            <div className='flex justify-center m-5'>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${newsDetailStore.data?.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
                alt={newsDetailStore.data?.post_title}
                className={`${showBanner ? 'block' : 'hidden'}`}
                onError={() => {
                  setShowBanner(false)
                }}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: newsDetailStore.data?.post_content }}></div>
          </div>
          <div className='md:col-span-3'>
            <h2 className='text-3xl font-bold mb-2 mt-8'>{t('newsevent')}</h2>
            <HeadingUnderline />
            <div className='grid grid-cols-2 gap-5 mt-5'>
              {newsDetailStore.otherData.map((item) => (
                <Card
                  key={item.id}
                  label={item.post_title.length > 30 ? item.post_title.slice(0, 30).concat('...') : item.post_title}
                  imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
                  href={`/news/${item.post_name}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(NewsDetail)
