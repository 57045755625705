import React from 'react'
import ContactSection from '../../components/ContactUs/ContactSection'
import ContactForm from '../../components/ContactUs/ContactForm'
import Breadcrumb from '../../components/Breadcrumb'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
  const { t } = useTranslation()

  const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('contact-us') }]

  return (
    <div className='flex justify-center'>
      <div className='max-w-6xl w-full mt-16 px-4'>
        <Breadcrumb items={breadcrumbItems} />
        <ContactSection />
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactUs
