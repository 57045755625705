import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { tourismStore } from '../../stores/_rootStore'
import { useForm } from 'react-hook-form'
import Breadcrumb from '../../components/Breadcrumb'
import i18n from '../../i18n'
import ModalDialog from '../../components/ModalDialog'
import location from '../../constants/location.json'

function Tourism() {
  const { t } = useTranslation()
  const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('tourism') }]
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    try {
      const res = await tourismStore.sendDownloadCalendarEvents(data)
      console.log({ res })
      window.open(res.url, '_blank', 'noopener,noreferrer')
      reset()
      setModalOpen(false)
    } catch (error) {}
  }

  // Wisata options
  const wisataOptions = [
    { label: 'Wisata Alam', value: 'wisata-alam' },
    { label: 'Wisata Budaya', value: 'wisata-budaya' },
    { label: 'Wisata Buatan', value: 'wisata-buatan' }
  ]

  // Handle checkbox change
  const handleCheckboxChange = (wisata) => {
    if (tourismStore.category.includes(wisata)) {
      tourismStore.unSelectCategory(wisata)
    } else {
      tourismStore.selectCategory(wisata)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await tourismStore.fetchData()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [i18n.language])

  return (
    <div className='flex flex-col justify-center mb-12'>
      <img src='/assets/images/image_slide/image_blank/banner4.png' className='w-full h-[335px] object-cover' alt='Banner' />

      <div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
        <div className='px-8'>
          <Breadcrumb items={breadcrumbItems} />
          <h1 className='text-3xl font-bold mb-2 mt-8'>{t('tourism')}</h1>

          <a href='https://jasirah.id/' target='_blank' rel='noreferrer'>
            <img src='/assets/images/image_footer/logo-jasirah.webp' className='w-24 mt-4' alt='Jasirah' />
          </a>
        </div>
        <div className='flex flex-col lg:flex-row gap-8 mt-5 px-8'>
          <div className='w-full lg:w-[360px]'>
            <div className='relative'>
              <input className='border p-2 rounded w-full' type='text' value={tourismStore.searchTerm} onChange={(e) => tourismStore.search(e.target.value)} placeholder={t('search')} />

              <i className='fa fa-search absolute right-3 top-3 text-gray-400'></i>
            </div>

            <select
              id='prefered_location'
              className='w-full px-3 py-2 mt-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
              value={tourismStore.region}
              onChange={(e) => tourismStore.selectRegion(e.target.value)}
            >
              <option value=''>{t('choose_region')}</option>
              {location.map((item, index) => {
                return (
                  <option key={index} value={item.name}>
                    {item.post_title_id}
                  </option>
                )
              })}
            </select>

            <h4 className='mt-8'>{t('category')}</h4>

            <div className='flex flex-col'>
              {wisataOptions.map((wisata, index) => (
                <label key={index} className='flex gap-2 text-sm font-semibold cursor-pointer'>
                  <input className='p-0' type='checkbox' value={wisata.value} checked={tourismStore.category.includes(wisata.value)} onChange={() => handleCheckboxChange(wisata.value)} />
                  {wisata.label}
                </label>
              ))}
            </div>

            <div className='w-full p-1 border border-emerald-600 rounded mt-8 bg-white'>
              <img src='/assets/images/tourism/Cover-Calendar-Events-2025.png' className='w-full' alt='Thumbnail' />
              <p className='mt-2'>{t('caption_thumbnail')}</p>
              <div onClick={() => setModalOpen(true)} className='bg-primary text-center text-white mt-4 p-2 cursor-pointer'>
                {t('download_btn')}
              </div>
            </div>

            <ModalDialog open={modalOpen} onOpenChange={setModalOpen}>
              <form onSubmit={handleSubmit(onSubmit)} className='w-full rounded-lg'>
                <h4 className='text-black font-bold'>{t('inputEmail')}</h4>
                <div className='w-full text-left mt-8'>
                  <label htmlFor='email' className='block text-gray-700'>
                    {t('email')}
                  </label>
                  <input
                    type='text'
                    id='email'
                    placeholder={t('email')}
                    className='w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    {...register('email', {
                      required: t('requiredName')
                    })}
                  />
                  {errors.email && <span className='text-red-500 text-sm'>{errors.email.message}</span>}
                </div>
                <button type='submit' className='btn-primary mt-8 w-full'>
                  {t('download')}
                </button>
              </form>
            </ModalDialog>
          </div>
          <div className='w-full'>
            {tourismStore.data.length === 0 && <p>{t('no-data')}</p>}
            {tourismStore.data.length > 0 && (
              <>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-5 bg-white'>
                  {tourismStore.data.map((item, index) => (
                    <div key={index} onClick={() => navigate(`/pariwisata/${item.post_name}`)} className='flex flex-col items-center cursor-pointer'>
                      <div className='h-[250px] w-[190px] bg-slate-900'>
                        <img
                          className='object-cover h-full'
                          src={`${process.env.REACT_APP_IMAGE_URL}${item?.post_meta.filter((meta) => meta.meta_key === 'banner')[0]?.meta_value}`}
                          alt={item.post_title}
                        />
                      </div>
                      <p className='font-semibold text-gray-500 mt-2'>{item.post_title}</p>
                    </div>
                  ))}
                </div>
                {tourismStore.hasNext && (
                  <div className='flex justify-center mt-5'>
                    <button className='bg-primary text-white py-2 px-4 rounded-md' onClick={tourismStore.nextPage}>
                      {t('view-more')}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Tourism);
