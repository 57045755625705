import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import industrialAreaDetailStore from '../../../stores/industrialAreaDetailStore'
import PageDetail from '../../../components/PageDetail'
import Card from '../../../components/Card'
import i18n from '../../../i18n'

function IndustrialAreaDetail() {
  const { t } = useTranslation()
  const { postName } = useParams()
  useEffect(() => {
    const fetchData = async () => {
      try {
        await industrialAreaDetailStore.fetchData(postName)
        await industrialAreaDetailStore.fetchOtherData()
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [postName, i18n.language])

  const ContentRight = () => {
    return (
      <>
        <h1 className='font-bold text-2xl pb-2'>{t('other-industrial-area')}</h1>
        <div className='grid grid-cols-2 gap-5'>
          {industrialAreaDetailStore.otherData.map((item) => (
            <Card
              key={item.id}
              imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
              label={item.post_title}
              href={`/investment/industrial-area/${item.post_name}`}
            />
          ))}
        </div>
      </>
    )
  }

  const breadcrumbItems = [
    { label: t('home'), href: '/' },
    { label: t('invest') },
    { label: t('facility-infrastructure') },
    { label: t('industrial-area-invest') },
    { label: industrialAreaDetailStore.data?.post_title }
  ]
  if (industrialAreaDetailStore.isLoading) return <div>Loading...</div>
  if (industrialAreaDetailStore.data !== undefined)
    return (
      <div>
        <PageDetail breadcrumbItems={breadcrumbItems} bannerImg={industrialAreaDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value} contentRight={<ContentRight />}>
          <h1 className='font-bold text-2xl pb-2'>{industrialAreaDetailStore.data.post_title}</h1>
          <hr />
          <p dangerouslySetInnerHTML={{ __html: industrialAreaDetailStore.data.post_content }}></p>
        </PageDetail>
      </div>
    )
}

export default observer(IndustrialAreaDetail)
