import React from 'react'
import { useTranslation } from 'react-i18next'

function LicenseSection() {
	const { t } = useTranslation()
	return (
    <div>
      <h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-2'>{t('license')}</h3>
      <a href='https://dpmptsp.jatengprov.go.id/perizinan?id=62' className='text-blue-600 hover:text-gold font-semibold text-2xl' target='_blank' rel='noreferrer'>
        {t('investation-license')}
      </a>
    </div>
  )
}

export default LicenseSection
