import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { investmentPotentialDetailStore } from '../../../stores/_rootStore'
import PageDetail from '../../../components/PageDetail'
import Card from '../../../components/Card'
import i18n from '../../../i18n'

function InvestmentPotentialDetail() {
	const { t } = useTranslation()
	const { postName } = useParams()
	useEffect(() => {
		const fetchData = async () => {
			try {
				await investmentPotentialDetailStore.fetchData(postName)
				await investmentPotentialDetailStore.fetchOtherData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		// eslint-disable-next-line
	}, [postName, i18n.language])

	const ContentRight = () => {
		return (
			<>
				<h1 className='font-bold text-2xl pb-2'>{t('other-portfolio')}</h1>
				<div className='grid grid-cols-2 gap-5'>
					{investmentPotentialDetailStore.otherData.map((item) => (
						<Card
							key={item.id}
							imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
							label={item.post_title}
							href={`/investment/investment-potential/${item.post_name}`}
						/>
					))}
				</div>
			</>
		)
	}

	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('invest') }, { label: t('portfolio') }, { label: investmentPotentialDetailStore.data?.post_title }]
	if (investmentPotentialDetailStore.isLoading) return <div>Loading...</div>
	if (investmentPotentialDetailStore.data !== undefined)
		return (
      <div>
        <PageDetail
          breadcrumbItems={breadcrumbItems}
          bannerImg={investmentPotentialDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}
          contentRight={<ContentRight />}
        >
          <h1 className='font-bold text-2xl pb-2'>{investmentPotentialDetailStore.data.post_title}</h1>
          <hr />
          <p dangerouslySetInnerHTML={{ __html: investmentPotentialDetailStore.data.post_content }}></p>
          {investmentPotentialDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'youtube-video-id').length !== 0 &&
            investmentPotentialDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'youtube-video-id')[0].meta_value !== '' && (
              <div className='mt-5 mb-10'>
                <iframe
                  title='YouTube video player'
                  className='w-full aspect-video'
                  allowfullscreen='allowfullscreen'
                  mozallowfullscreen='mozallowfullscreen'
                  msallowfullscreen='msallowfullscreen'
                  oallowfullscreen='oallowfullscreen'
                  webkitallowfullscreen='webkitallowfullscreen'
                  src={`https://www.youtube.com/embed/${investmentPotentialDetailStore.data.post_meta.filter((meta) => meta.meta_key === 'youtube-video-id')[0].meta_value}`}
                ></iframe>
              </div>
            )}
        </PageDetail>
      </div>
    )
}

export default observer(InvestmentPotentialDetail)
