import React, { useEffect } from 'react'
import { investmentChallengeStore } from '../../stores/_rootStore'
import i18n from '../../i18n'
import { observer } from 'mobx-react'

function AboutIC() {
  useEffect(() => {
    const fetchData = async () => {
      await investmentChallengeStore.fetchAboutIC()
    }
    fetchData()
    // eslint-disable-next-line
  }, [i18n.language])
  return (
    <div>
      <div className='mb-2'>
        {investmentChallengeStore.isLoading && <div>Loading...</div>}
        {investmentChallengeStore.aboutIC && (
          <div className='mt-2'>
            <img src={`${process.env.REACT_APP_IMAGE_URL}${investmentChallengeStore.aboutIC.post_meta.filter((item) => item.meta_key === 'banner')[0].meta_value}`} alt='Banner IC' />
            <div className='innerhtml' dangerouslySetInnerHTML={{ __html: investmentChallengeStore.aboutIC.post_content }}></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(AboutIC)
