import React, { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { investmentChallengeStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useTranslation } from 'react-i18next'

function ModalLogin() {
  const { t } = useTranslation()
  const [mode, setMode] = useState('login') // login, register, forgot-password

  const changeMode = (mode) => {
    setMode(mode)
    investmentChallengeStore.name = ''
    investmentChallengeStore.login_key = ''
    investmentChallengeStore.password = ''
    investmentChallengeStore.passwordConfirm = ''
    investmentChallengeStore.birthday = ''
    investmentChallengeStore.birth_place = ''
  }
  return (
    <Dialog.Root open={investmentChallengeStore.isModalLoginOpen} onOpenChange={() => (investmentChallengeStore.isModalLoginOpen = !investmentChallengeStore.isModalLoginOpen)}>
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content
          className='DialogContent'
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <VisuallyHidden>
            <Dialog.Title>{mode}</Dialog.Title>
          </VisuallyHidden>
          <div className='flex justify-end'>
            <Dialog.Close>
              <Cross1Icon />
            </Dialog.Close>
          </div>
          <div className='flex flex-col justify-center gap-3'>
            {mode === 'login' && (
              <>
                <h1 className='text-2xl font-bold text-center'>{t('login')}</h1>
                <p className='text-center'>{t('only-government')}</p>
                <input
                  type='text'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('inputEmail')}
                  value={investmentChallengeStore.login_key}
                  onChange={(e) => {
                    investmentChallengeStore.login_key = e.target.value
                  }}
                />
                <input
                  type='password'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('inputPassword')}
                  value={investmentChallengeStore.password}
                  onChange={(e) => {
                    investmentChallengeStore.password = e.target.value
                  }}
                />
                <div className='flex justify-end'>
                  <button
                    className='text-gold hover:opacity-80'
                    onClick={() => {
                      changeMode('forgot-password')
                    }}
                  >
                    {t('forgot-password')}?
                  </button>
                </div>
                <button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.login} disabled={investmentChallengeStore.isLoading}>
                  {investmentChallengeStore.isLoading ? 'Loading...' : t('login')}
                </button>
                <p className='text-center'>
                  {t('dont-have-account')}
                  <button
                    className='text-gold hover:opacity-80'
                    onClick={() => {
                      changeMode('register')
                    }}
                  >
                    {t('register')}
                  </button>
                </p>
              </>
            )}
            {mode === 'register' && (
              <>
                <h1 className='text-2xl font-bold text-center'>Daftar</h1>
                <input
                  type='text'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-name-placeholder')}
                  value={investmentChallengeStore.name}
                  onChange={(e) => (investmentChallengeStore.name = e.target.value)}
                />
                <input
                  type='text'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-email-placeholder')}
                  value={investmentChallengeStore.login_key}
                  onChange={(e) => (investmentChallengeStore.login_key = e.target.value)}
                />
                <input
                  type='date'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  value={investmentChallengeStore.birthday}
                  onChange={(e) => (investmentChallengeStore.birthday = e.target.value)}
                />
                <input
                  type='text'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-birthplace-placeholder')}
                  value={investmentChallengeStore.birth_place}
                  onChange={(e) => (investmentChallengeStore.birth_place = e.target.value)}
                />
                <input
                  type='password'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-password-placeholder')}
                  value={investmentChallengeStore.password}
                  onChange={(e) => (investmentChallengeStore.password = e.target.value)}
                />
                <input
                  type='password'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-confirm-password-placeholder')}
                  value={investmentChallengeStore.confirm_password}
                  onChange={(e) => (investmentChallengeStore.confirm_password = e.target.value)}
                />

                <button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.register} disabled={investmentChallengeStore.isLoading}>
                  {investmentChallengeStore.isLoading ? 'Loading...' : t('register')}
                </button>
                <p className='text-center'>
                  {t('already-have-account')}
                  <button className='text-gold hover:opacity-80' onClick={() => changeMode('login')}>
                    {t('login')}
                  </button>
                </p>
              </>
            )}
            {mode === 'forgot-password' && (
              <>
                <h1 className='text-2xl font-bold text-center'>{t('forgot-password')}</h1>
                <input
                  type='text'
                  className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
                  placeholder={t('input-email-placeholder')}
                  value={investmentChallengeStore.login_key}
                  onChange={(e) => (investmentChallengeStore.login_key = e.target.value)}
                />

                <button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.forgotPassword} disabled={investmentChallengeStore.isLoading}>
                  {investmentChallengeStore.isLoading ? 'Loading...' : t('submit')}
                </button>
                <button
                  className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80'
                  onClick={() => {
                    changeMode('login')
                  }}
                >
                  {t('back')}
                </button>
              </>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default observer(ModalLogin)
