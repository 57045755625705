import React, { useEffect, useState } from 'react'
import { regionStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeadingUnderline from '../../components/HeadingUnderline'
import Breadcrumb from '../../components/Breadcrumb'
import Card from '../../components/Card'
import i18n from '../../i18n'

function RegionDetail() {
  const { postName } = useParams()
  const { t } = useTranslation()
  const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('regional') }, { label: regionStore.data?.post_title }]
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await regionStore.fetchData(postName)
        await regionStore.fetchOtherData()
      } catch (error) {
        console.error(error)
      }
    }
    setShowBanner(true)
    fetchData()
    // eslint-disable-next-line
  }, [postName, i18n.language, postName])

  return (
    <div className='flex flex-col justify-center mb-12'>
      <div className='w-full'>
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}${regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'banner')[0]?.meta_value}`}
          alt={regionStore.data?.post_title}
          className={`${showBanner ? 'block' : 'hidden'} w-full h-[25vh] object-cover`}
          onError={() => {
            setShowBanner(false)
          }}
        />
      </div>
      <div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
        <Breadcrumb items={breadcrumbItems} />
        <div className='grid grid-cols-1 md:grid-cols-10 gap-10 gap-y-5 mt-5'>
          <div className='md:col-span-7'>
            <h1 className='text-3xl font-bold mb-2 mt-8'>{regionStore.data?.post_title}</h1>
            <HeadingUnderline />
            <div className='flex flex-col gap-2 mt-5'>
              <p>
                <span className='font-bold'>{t('region-type')}: </span>
                {regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'jenis-wilayah')[0]?.meta_value}
              </p>
              <p>
                <span className='font-bold'>{t('region-area')}: </span>
                {regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'luas-wilayah')[0]?.meta_value} Km<sup>2</sup>
              </p>
              <div>
                <p className='font-bold'>{t('region-border')}: </p>
                <p dangerouslySetInnerHTML={{ __html: regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'perbatasan')[0]?.meta_value }}></p>
              </div>
              <div>
                <p className='font-bold'>{t('region-potential-sector')}: </p>
                <p dangerouslySetInnerHTML={{ __html: regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'sektor-potensial')[0]?.meta_value }}></p>
              </div>
              <div>
                <p className='font-bold'>{t('region-top-3-potential-sector')}: </p>
                <p dangerouslySetInnerHTML={{ __html: regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'top-sektor-potensial')[0]?.meta_value }}></p>
              </div>
              <p>
                <p className='font-bold'>{t('region-product-potential')}: </p>
                <p dangerouslySetInnerHTML={{ __html: regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'produk-potensial')[0]?.meta_value }}></p>
              </p>
              <p>
                <span className='font-bold'>{t('region-total-vocational-school-and-total-student')}: </span>
                {regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'jumlah-sekolah-vokasi-dan-jumlah-siswanya')[0]?.meta_value}
              </p>
              <p>
                <span className='font-bold'>{t('region-minimum-worker-wage')}: </span>
                {regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'upah-minimum-pekerja')[0]?.meta_value}
              </p>
              <p>
                <span className='font-bold'>{t('region-main-tourist-destination')}: </span>
                {regionStore.data?.post_meta.filter((meta) => meta.meta_key === 'destinasi-wisata-utama')[0]?.meta_value}
              </p>
            </div>
          </div>
          <div className='md:col-span-3'>
            <h2 className='text-3xl font-bold mb-2 mt-8'>{t('other-region')}</h2>
            <HeadingUnderline />
            <div className='grid grid-cols-2 gap-5 mt-5'>
              {regionStore.otherData.map((item) => (
                <Card
                  key={item.id}
                  label={item.post_title.length > 30 ? item.post_title.slice(0, 30).concat('...') : item.post_title}
                  imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0]?.meta_value}`}
                  href={`/region/${item.post_name}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(RegionDetail)
