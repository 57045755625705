import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { resetPasswordStore } from '../../stores/_rootStore'
import { useSearchParams } from 'react-router-dom'
import * as Dialog from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { Cross1Icon } from '@radix-ui/react-icons'
import { observer } from 'mobx-react'

function ResetPassword() {
  const { t } = useTranslation()
  const onSubmit = () => {
    resetPasswordStore.sendResetPassword()
  }

  const [searchParams] = useSearchParams()

  useEffect(() => {
    resetPasswordStore.activation_key = searchParams.get('activation_key')
    resetPasswordStore.login_key = searchParams.get('login_key')
    console.log(resetPasswordStore.activation_key, resetPasswordStore.login_key)
    if ((resetPasswordStore.activation_key === '' || resetPasswordStore.activation_key === null) && (resetPasswordStore.login_key === '' || resetPasswordStore.login_key === null)) {
      window.location.replace('/')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Dialog.Root open={resetPasswordStore.isModalSuccessOpen} onOpenChange={() => (resetPasswordStore.isModalSuccessOpen = !resetPasswordStore.isModalSuccessOpen)}>
        <Dialog.Portal>
          <Dialog.Overlay className='DialogOverlay' />
          <Dialog.Content
            className='DialogContent'
            onOpenAutoFocus={(e) => {
              e.preventDefault()
            }}
          >
            <VisuallyHidden>
              <Dialog.Title>Success</Dialog.Title>
            </VisuallyHidden>
            <div className='flex justify-end'>
              <Dialog.Close>
                <Cross1Icon />
              </Dialog.Close>
            </div>
            <div className='flex flex-col justify-center gap-3'>
              <h1 className='text-2xl font-bold text-center'>{resetPasswordStore.message}</h1>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <div className='flex flex-col justify-center mb-12'>
        <div className='max-w-6xl w-full mt-8 md:mt-18 p-2 md:p-0 md:mx-auto'>
          <h1 className='text-3xl font-bold mb-2 mt-8'>{t('reset-password')}</h1>
          <div className='flex gap-3'>
            <div className='flex flex-col gap-2'>
              <p>Password Baru</p>
              <p>Konfirmasi Password</p>
            </div>
            <div className='flex flex-col gap-2'>
              <input
                type='password'
                name='password'
                className='border w-96 border-slate-400 focus:outline-none focus:border-gold'
                value={resetPasswordStore.newPassword}
                onChange={(e) => {
                  resetPasswordStore.newPassword = e.target.value
                }}
              />
              <input
                type='password'
                name='password'
                className='border w-96 border-slate-400 focus:outline-none focus:border-gold'
                value={resetPasswordStore.confirmPassword}
                onChange={(e) => {
                  resetPasswordStore.confirmPassword = e.target.value
                }}
              />
            </div>
          </div>
          <button className='py-1 px-4 mt-5 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default observer(ResetPassword)
