import React from "react";
import { useTranslation } from "react-i18next";
import HeadingUnderline from "../HeadingUnderline";

const ContactSection = () => {
	const { t } = useTranslation();
	return (
    <>
      <div className='text-left'>
        <h1 className='text-3xl font-bold mb-2 mt-8'>{t('contact-us')}</h1>
        <HeadingUnderline />
      </div>
      <div className='flex flex-col lg:flex-row gap-8 w-full mt-8 px-8'>
        <div className='bg-slate-500 bg-opacity-20 w-full py-8 flex flex-col items-center justify-center gap-4'>
          <img className='h-24' id='img-left' src='../assets/images/logo kerisjateng ungu.png' alt='Logo Keris Jateng' />
          <div className='bg-primary-light xl:block w-full text-white font-semibold text-center py-1'>
            <h3>{t('contact-us')}</h3>
          </div>

          <table className='table-auto'>
            <tbody>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-map-marker fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <a
                    href='https://www.google.co.id/maps/place/Jl.+Imam+Bardjo+SH,+Pleburan,+Semarang+Sel.,+Kota+Semarang,+Jawa+Tengah+50241/@-6.9935772,110.4233414,17z/data=!4m2!3m1!1s0x2e708b5e4c7faaa7:0x5cdc93fb242b1f10'
                    title='Keris Jateng'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-left'
                  >
                    <p>Jl. Imam Bardjo SH No.04</p>
                    <p>Pleburan, Kota Semarang</p>
                    <p>Jawa Tengah 50421</p>
                  </a>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-phone fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <p>(+6224) 8310246 ext 8221</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td className='text-left'>
                  <p>(+6224) 8417791</p>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-envelope-o fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <p>centraljavacorridor@gmail.com</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='bg-slate-500 bg-opacity-20 w-full py-8 flex flex-col items-center justify-center gap-4'>
          <img className='h-24' id='img-mid' src='../assets/images/Logo_DPMTSP.png' alt='DPMTSP Logo' />
          <div className='bg-primary-light xl:block w-full text-white font-semibold text-center py-1'>
            <h3>{t('contact-us')}</h3>
          </div>

          <table className='table-auto'>
            <tbody>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-map-marker fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <a
                    href='https://www.google.co.id/maps/place/DPMPTSP+Provinsi+Jawa+Tengah/@-6.9832879,110.4054576,17z/data=!3m1!4b1!4m5!3m4!1s0x2e708b4927db6189:0x439c487706e546a4!8m2!3d-6.9832879!4d110.4076463'
                    title='DPMPTSP Provinsi Jawa Tengah'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-left'
                  >
                    <p>Jl. Mgr Soegiyopronoto</p>
                    <p>No.1 Kota Semarang,</p>
                    <p>Jawa Tengah 50131</p>
                  </a>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-phone fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <p>(+6224) 3547091</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td className='text-left'>
                  <p>3547438 - 3541487</p>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <img src='../assets/images/investment/how-to-invest/Asset-globe.png' alt='Globe Icon' />
                </td>
                <td className='text-left'>
                  <a href='http://dpmptsp.jatengprov.go.id/' target='_blank' rel='noopener noreferrer'>
                    <p>dpmptsp.jatengprov.go.id</p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='bg-slate-500 bg-opacity-20 w-full py-8 flex flex-col items-center justify-center gap-4'>
          <img className='h-24' id='img-right' src='../assets/images/Logo_BKPM.webp' alt='BKPM Logo' />
          <div className='bg-primary-light xl:block w-full text-white font-semibold text-center py-1'>
            <h3>{t('contact-us')}</h3>
          </div>

          <table className='table-auto'>
            <tbody>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-map-marker fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <a
                    href='https://www.google.co.id/maps/place/Badan+Koordinasi+Penanaman+Modal+(BKPM)/@-6.2268264,106.816056,19z/data=!4m13!1m7!3m6!1s0x2e69f1561dd21e47:0x3454eb0c8ed94391!2sJl.+Jend.+Gatot+Subroto+No.44,+Karet+Semanggi,+Kecamatan+Setiabudi,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12930!3b1!8m2!3d-6.2268889!4d106.8163582'
                    title='Badan Koordinasi Penanaman Modal (BKPM)'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-left'
                  >
                    <p>Jl. Jend. Gatot Subroto</p>
                    <p>No.44, Daerah Khusus</p>
                    <p>Ibukota Jakarta 12930</p>
                  </a>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-phone fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <p>(+6221) 5252008</p>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <i className='fa fa-envelope-o fa-icon' aria-hidden='true'></i>
                </td>
                <td className='text-left'>
                  <p>info@bkpm.go.id</p>
                </td>
              </tr>
              <tr>
                <td className='align-top pr-2'>
                  <img src='../assets/images/investment/how-to-invest/Asset-globe.png' alt='Globe Icon' />
                </td>
                <td className='text-left'>
                  <a href='http://www.bkpm.go.id/' target='_blank' rel='noopener noreferrer'>
                    <p>www.bkpm.go.id</p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
};

export default ContactSection;
