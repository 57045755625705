import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'
import InvestmentSelect from '../../components/Investment/InvestmentSelect'
import RegulationIncentive from './RegulationIncentive'
import InvestmentPotential from './InvestmentPotential'
import FacilityInfrastructure from './FacilityInfrastructure'
import SuccessStory from './SuccessStory'
import { useLocation, useSearchParams } from 'react-router-dom'

const Investment = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const [searchParams] = useSearchParams(location.search)
	let tab = searchParams.get('tab')
	const [currentPage, setCurrentPage] = useState(tab || 'procedure-invest')

	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('invest') }]

	return (
		<div className='flex flex-col justify-center mb-12'>
			<InvestmentSelect currentPage={currentPage} setCurrentPage={setCurrentPage} />
			<div className='max-w-6xl w-full mt-16 md:mt-48 p-2 md:p-0 md:mx-auto'>
				<Breadcrumb items={breadcrumbItems} />
				{currentPage === 'procedure-invest' && <RegulationIncentive />}
				{currentPage === 'portfolio' && <InvestmentPotential />}
				{currentPage === 'facility-infrastructure' && <FacilityInfrastructure />}
				{currentPage === 'success-story' && <SuccessStory />}
			</div>
		</div>
	)
}

export default Investment
