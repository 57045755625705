import React from 'react'
import Marquee from "react-fast-marquee";

const Footer = () => {
	return (
		<>
			<div className='md:h-[100px]'></div>
			<div className='bg-primary bottom-0 bg-primary md:h-[100px] z-50 fixed flex items-center justify-center w-full'>
				<div className='w-full mb-5'>
					<Marquee>
						{/* <div className='md:flex justify-around gap-5 items-center h-full mb-3 hidden' style={{flex: '0 0 auto'}}> */}
						<div className='mx-10'>

							<a href='https://jatengprov.go.id' >
								<img src='/assets/images/Asset pemprov jateng_1.webp' alt='Pemprov Jateng' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='http://dpmptsp.jatengprov.go.id/perizinan'>
								<img src='/assets/images/Asset DPMPTSP.webp' alt='DPMPTSP Jateng' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='https://disperindag.jatengprov.go.id/v3/' target='_blank' rel='noopener noreferrer'>
								<img src='/assets/images/disperindag-logo.webp' alt='Disperindag' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='https://disporapar.jatengprov.go.id/' target='_blank' rel='noopener noreferrer'>
								<img src='/assets/images/disporapar-logo.webp' alt='Disporapar' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='http://www.bi.go.id'>
								<img src='/assets/images/Asset BI.webp' alt='Bank Indonesia' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='http://www.bkpm.go.id/'>
								<img src='/assets/images/Asset BKPM.webp' alt='BKPM' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='http://www.bi.go.id/en/iru/default.aspx'>
								<img src='/assets/images/Asset IRU.webp' alt='IRU' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='https://jasirah.id/' target='_blank' rel='noopener noreferrer'>
								<img src='/assets/images/Asset Jasirah.webp' alt='Jasirah' className='h-11' />
							</a>
						</div>
						<div className='mx-10'>
							<a href='https://visitjawatengah.jatengprov.go.id/id' target='_blank' rel='noopener noreferrer' className='mx-10'>
								<img src='/assets/images/visit jateng.png' alt='Jasirah' className='h-11' />
							</a>
						</div>
						{/* </div> */}
					</Marquee>
					<div className='bg-primary text-white font-bold bottom-0 w-full z-50 text-center px-4'>Copyright © 2024 Keris Jateng. All Rights Reserved</div>
				</div>
			</div>
		</>
	)
}

export default Footer
