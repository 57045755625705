import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../Card'
import { investmentPotentialStore } from '../../../stores/_rootStore'
import { observer } from 'mobx-react'

function InvestmentPotentialSection({ category }) {
	const { t } = useTranslation()
	const [searchTerm, setSearchTerm] = useState('')
	useEffect(() => {
		const fetchData = async () => {
			switch (category) {
				case 'all-categories':
					investmentPotentialStore.slug = 'potensi-investasi'
					break
				case 'agriculture-forestry-fishery':
					investmentPotentialStore.slug = 'investasi-pertanian-kehutanan-perikanan'
					break
				case 'manufacturing-industry':
					investmentPotentialStore.slug = 'investasi-industri-pengolahan'
					break
				case 'electricity-gas-procurement':
					investmentPotentialStore.slug = 'investasi-pengadaan-listrik-gas'
					break
				case 'waste-management':
					investmentPotentialStore.slug = 'investasi-pengelolaan-sampah'
					break
				case 'trade':
					investmentPotentialStore.slug = 'investasi-perdagangan'
					break
				case 'other-services':
					investmentPotentialStore.slug = 'investasi-jasa-lainnya'
					break
				default:
					investmentPotentialStore.slug = 'potensi-investasi'
					break
			}
			try {
				await investmentPotentialStore.fetchData()
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		return () => {
			investmentPotentialStore.resetState()
		}
	}, [category])
	return (
		<div>
			<h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-5 hidden md:block'>{t(category)}</h3>
			<div className='flex md:justify-end'>
				<div className='relative'>
					<input
						type='text'
						className='border border-gray-300 py-2 pl-2 pr-7 focus:outline-none focus:border-gold'
						placeholder={t('search')}
						onChange={(e) => setSearchTerm(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								investmentPotentialStore.search(searchTerm)
							}
						}}
					/>
					<i className='fa fa-search absolute right-2 top-3 text-gray-300'></i>
				</div>
			</div>
			<div className='grid grid-cols-2 md:grid-cols-3 gap-4 py-5'>
				{investmentPotentialStore.data.map((item) => (
					<Card
						key={item.id}
						imgSrc={`${process.env.REACT_APP_IMAGE_URL}${item.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
						label={item.post_title}
						href={`/investment/investment-potential/${item.post_name}`}
					/>
				))}
				{investmentPotentialStore.isLoading ? <p>Loading...</p> : investmentPotentialStore.data.length === 0 && <p>{t('no-data')}</p>}
			</div>
			{investmentPotentialStore.hasNext && (
				<div className='flex justify-center'>
					<button className='bg-gold text-white py-2 px-4 rounded-md' onClick={investmentPotentialStore.nextPage}>
						{t('view-more')}
					</button>
				</div>
			)}
		</div>
	)
}

export default observer(InvestmentPotentialSection)
