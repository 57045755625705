import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { investmentChallengeStore } from '../../stores/_rootStore'
import { observer } from 'mobx-react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

function ModalChangePassword() {
  return (
    <Dialog.Root
      open={investmentChallengeStore.isModalChangePasswordOpen}
      onOpenChange={() => (investmentChallengeStore.isModalChangePasswordOpen = !investmentChallengeStore.isModalChangePasswordOpen)}
    >
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content
          className='DialogContent'
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <VisuallyHidden>
            <Dialog.Title>Change Password</Dialog.Title>
          </VisuallyHidden>
          <div className='flex justify-end'>
            <Dialog.Close>
              <Cross1Icon />
            </Dialog.Close>
          </div>
          <div className='flex flex-col justify-center gap-3'>
            <h1 className='text-2xl font-bold text-center'>Change Password</h1>
            <input
              type='text'
              className='border border-slate-400 p-2 focus:outline-none focus:border-gold'
              placeholder={'Masukkan email'}
              value={investmentChallengeStore.login_key}
              onChange={(e) => (investmentChallengeStore.login_key = e.target.value)}
            />

            <button className='p-3 bg-gold text-white font-bold rounded-md hover:opacity-80' onClick={investmentChallengeStore.changePassword} disabled={investmentChallengeStore.isLoading}>
              {investmentChallengeStore.isLoading ? 'Loading...' : 'Kirim'}
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default observer(ModalChangePassword)
